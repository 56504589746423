/* eslint-disable camelcase,no-console,no-undef */
import axios from 'axios';
import store from './store';

import { BASE_URL } from '../utils/config';

/**
 *
 * parse error response
 */
const parseError = (messages) => {
  if (messages) {
    if(messages.data && messages.data.code === -114) {
      localStorage.clear();
      window.location.replace('/user');
      return {message: messages.data.message};
    } else return {message: messages.data.message};
  } else {
    return Promise.reject({ messages: 'Unrecognized error' });
  }
};

/**
 * parse response
 */
const parseBody = (response) => (
  response.data.status === 'success' ? response.data.data : parseError(response.data)
);

/**
 * axios instance
 */
let instance = axios.create({
  baseURL: BASE_URL
});

const parseConfig = (config) => {
  let configuration = {...config};
  const userProfileAuth = store.getState().profileData.userAuth;
  const authToken = localStorage.getItem('AUTH_TOKEN');
  if((userProfileAuth && authToken) && userProfileAuth !== authToken) {
    window.location.reload();
  }
  return configuration;
};

// request header
instance.interceptors.request.use((config) => parseConfig(config), (error) => Promise.reject(error));

// response parse
instance.interceptors.response.use((response) => parseBody(response), (error) => (
  error.response ? parseError(error.response.data) : Promise.reject(error)
));

export const http = instance;