/* eslint-disable no-console, no-undef, no-case-declarations, camelcase */
import * as actionTypes from '../../actions/actionTypes';
const initialState = {
  teamsUsersData: [],
  teamsCount:null,
  teamUsers:[],
  teamDetailsData:[],
  teamIconsData:[],
  teamsCreatedStatus:null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.GET_COMPANY_TEAM_MEMBERS:
    return{
      ...state,
      teamsUsersData: action.teamsUsersData
    };
  case actionTypes.GET_COMPANY_TEAM_COUNT:
    return{
      ...state,
      teamsCount: action.teamsCount
    };
  case actionTypes.GET_USERS_ADD_TO_TEAM:
    return{
      ...state,
      teamUsers: action.teamUsers
    };
  case actionTypes.TEAM_DETAILS_BY_ID:
    return{
      ...state,
      teamDetailsData: action.teamDetailsData
    };
  case actionTypes.TEAM_ICONS:
    return{
      ...state,
      teamIconsData: action.teamIconsData
    }; 
  case actionTypes.TEAM_CREATED:
    return{
      ...state,
      teamsCreatedStatus: action.teamsCreatedStatus
    };
  case actionTypes.TEAM_NOT_CREATED:
    return{
      ...state,
      teamsCreatedStatus: action.teamsCreatedStatus
    };       
  default:
    break;
  }
  return state;
};

export default reducer;