/* eslint-disable no-console,no-undef, no-case-declarations */
import * as actionTypes from '../../actions/actionTypes';

const initialState = {
  error: null,
  token: '',
  isLoggingIn: false,
  isLoggedIn: false,
  isAdmin: null,
  networkError: null,
  contactUsInfo: null,
  contactError: null,
  isLoading: false,
  role: 'USER',
  userEmailToken: '',
  contactPopUpStatus: false,
  contactSuccess: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

  case actionTypes.CONTACT_LOADING:
    return {
      ...state,
      isLoading: true
    };

  case actionTypes.AUTHENTICATED_USER:
    return {
      ...state,
      token: action.token || ''
    };

  case actionTypes.LOGIN_ATTEMPT:
    return {
      ...state,
      isLoggingIn: true,
      isLoggedIn: false,
      username: action.username,
    };

  case actionTypes.LOGIN_FAILED:
    return {
      ...state,
      error: action.error,
      isLoggingIn: false,
      isLoggedIn: false,
    };

  case actionTypes.LOGIN_SUCCESSFULLY:
    return {
      ...state,
      error: null,
      isLoggingIn: false,
      isLoggedIn: true,
    };

  case actionTypes.AUTHENTICATION_ERROR:
    return {
      ...state,
      error: action.payload,
    };

  case actionTypes.USER_LOGOUT:
    return {
      ...state,
      message: action.message
    };

  case actionTypes.USER_LOGOUT_FAILED:
    return {
      ...state,
      message: action.message
    };

  case actionTypes.SET_ADMIN_USER:
    return {
      ...state,
      isAdmin: action.isAdmin
    };

  case actionTypes.GET_ADMIN_USER:
    return {
      ...state,
      isAdmin: state.isAdmin
    };

  case actionTypes.NETWORK_ERROR:
    return {
      ...state,
      isLoading: false,
      networkError: action.networkError
    };

  case actionTypes.SAVE_CONTACT_US_INFO:
    let contactUsInfo = {};
    if(_.isNull(state.contactUsInfo)) {
      contactUsInfo[action.name] = action.value
    } else {
      contactUsInfo = {...state.contactUsInfo};
      contactUsInfo[action.name] = action.value;
    }

    return {
      ...state,
      contactUsInfo
    };

  case actionTypes.CONTACT_US_ERROR:
    return {
      ...state,
      contactError: action.contactError,
      isLoading: false
    };

  case actionTypes.CONTACT_US_SUCCESS:
    return {
      ...state,
      contactUsInfo: null,
      isLoading: false,
      contactSuccess: action.contactSuccess,
      contactPopUpStatus: action.contactPopUpStatus,
    };

  case actionTypes.SET_USER_ROLE:
    return {
      ...state,
      role: action.role
    };

  case actionTypes.GET_USER_ROLE:
    const isAdmin = localStorage ? localStorage.getItem('ADMIN_USER') : false;
    const isWellnessChampion = localStorage ? localStorage.getItem('WELLNESS_CHAMPION') : false;
    const isWellnessAdmin = localStorage ? localStorage.getItem('WELLNESS_ADMIN') : false;

    const role = isAdmin === 'true' ? 'ADMIN' : isWellnessAdmin === 'true' ? 'WELLNESS_ADMIN' : isWellnessChampion === 'true' ? 'WELLNESS_CHAMPION' : state.role ? state.role : 'USER';
    return {
      ...state,
      role: role
    };
  case actionTypes.PRICING_ENTERPRISE_SUCCESS:
    return {
      ...state,
      isLoading: false
    };

  case actionTypes.PRICING_ENTERPRISE_ERROR:
    return {
      ...state,
      isLoading: false,
      pricingError: action.pricingError
    };
  case actionTypes.AUTHENTICATED_USER_EMAIL_TOKEN:
    return {
      ...state,
      userEmailToken: action.userEmailToken
    };
  default: break;
  }

  return state;
};

export default reducer;
