import * as actionTypes from '../../actions/actionTypes';

const initialState = {
  networkError: null,
  getLogWorkoutCategories: [],
  isLoading: false,
  getLogWorkoutDetails: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.NETWORK_ERROR:
    return {
      ...state,
      isLoading: false,
      networkError: action.networkError
    };
  case actionTypes.GET_USER_LOG_WORKOUT_CATEGORY:
    return {
      ...state,
      getLogWorkoutCategories: action.getLogWorkoutCategories
    };
  case actionTypes.USER_LOG_WORKOUT_LOADING_TRUE:
    return {
      ...state,
      isLoading: true
    };
  case actionTypes.USER_LOG_WORKOUT_LOADING_FALSE:
    return {
      ...state,
      isLoading: false
    };
  case actionTypes.GET_USER_LOG_WORKOUT:
    return {
      ...state,
      getLogWorkoutDetails: action.getLogWorkoutDetails
    };
  default:
    break;
  }
  return state;
};

export default reducer;