/* eslint-disable no-console, no-undef, no-case-declarations, camelcase */
import * as actionTypes from '../../actions/actionTypes';
const initialState = {
  challengeOverviewData: null,
  challengeTopLeadersData: null,
  challengeTopParticpantsData: null,
  activityChallengeData: null,
  behaviorChallengeData: null,
  challengeData: {},
  adminChallengeOverviewData:null,
  adminChallengeActive:null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.CHALLENGE_OVERVIEW:
    return{
      ...state,
      challengeOverviewData: action.challengeOverviewData
    };
  case actionTypes.CHALLENGE_TOP_LEADERS:
    return{
      ...state,
      challengeTopLeadersData: action.challengeTopLeadersData
    };
  case actionTypes.CHALLENGE_TOP_PARTIPANTS:
    return{
      ...state,
      challengeTopParticpantsData: action.challengeTopParticpantsData
    };
  case actionTypes.ACTIVITY_CHALLENGE_DATA:
    return{
      ...state,
      activityChallengeData: action.activityChallengeData
    };
  case actionTypes.BEHAVIOR_CHALLENGE_DATA :
    return{
      ...state,
      behaviorChallengeData: action.behaviorChallengeData
    };
  case actionTypes.SAVE_CHALLENGE_INFORMATION:
    const challengeData = {
      ...state.challengeData,
      [action.name]: action.value
    };
    return {
      ...state,
      challengeData: challengeData,
    };
  case actionTypes.GET_CHALLENGE_INFORMATION:
    return {
      ...state
    };
  case actionTypes.ADMIN_CHALLENGE_OVERVIEW:
    return{
      ...state,
      adminChallengeOverviewData: action.adminChallengeOverviewData
    };
  case actionTypes.ADMIN_CHALLENGE_ACTIVE:
    return{
      ...state,
      adminChallengeActive: action.adminChallengeActive
    };
  default:
    break;
  }
  return state;
};

export default reducer;